<template>
  <v-card
      class="impress"
      min-height="45vh"
    >

      <v-card-title>
        <a target="_new" :href="this.taxonLink">{{ this.prediction }}</a>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col class="col-12">
            <a target="_new" :href="this.taxonLink">
              <core-Image
                :file="this.crop.file"
                :box="this.crop.box"
                :max-height="400"
                :contain="true"
                />
            </a>
            <v-btn icon color="indigo" id="close" @click="$emit('close')">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="this.prediction">
          <v-col class="col-12">
            <h3>Example images of from GBIF</h3>
            <core-SpeciesExamples
              :prediction="this.prediction"
              :info="this.pred_info"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
</template>

<script>

export default {
  name: 'InfoCrop',

  props: {

    crop: {
      type: Object,
    },
  },

  computed: {

    taxonLink: function() {
      var taxonID = undefined;
      const info = this.pred_info;
      if (info === undefined)
        return undefined;

      const labels = this.pred_info.labels;

      for (let i in labels){
        const label = labels[i];
        if(label.id !== undefined){
          taxonID = label.id;
          break;
        }

      }

      return `https://gbif.org/occurrence/gallery?media_type=StillImage&taxon_key=${taxonID}`;
      // return `https://gbif.org/species/${taxonID}`;
    },

    prediction: function(){
      if (this.crop === undefined || this.crop.box === undefined)
        return "Unknown";

      return this.crop.box.pred;
    },

    pred_info: function() {

      if (this.crop === undefined || this.crop.box === undefined)
        return {};

      return this.crop.box.pred_info;
    },

  },

}

</script>


<style scoped>
  .overlay {
    position: relative;
  }

  #close {
    top: 10px;
    right: 10px;
    position: absolute;
    z-index: 1000;
  }

</style>
