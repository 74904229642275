<template>
    <div>
      <v-card
        @drop.prevent="onDrop($event)"
        @dragover.prevent="dragover = true"
        @dragenter.prevent="dragover = true"
        @dragleave.prevent="dragover = false"
        :class="{
          'grey lighten-2': dragover,
        }"
      >
        <v-card-title>
          <div v-if="file">Your image</div>
          <div v-else>Upload your image</div>
        </v-card-title>
        <v-card-text>
          <input type="file" ref="file" style="display: none" v-on:change="onClick($event)">
          <div v-if="file">
            <v-row>
              <v-col class="col-12 col-xl-8">
                <div class="overlay">

                  <core-Image
                    ref="image"
                    :file="file"
                    @loaded="loaded"
                  />

                  <core-BoundingBox
                    v-for="(box, i) in boxes"
                    ref="boxComponents"
                    :key="i"
                    :position="box.box"
                    :label="box.pred"
                    :score="box.score"
                    @selected="select(i, box)"
                  />


                </div>
              </v-col>

              <v-col class="col-12 col-xl-4">

                <v-row v-if="!isMobile">
                  <v-col class="col-12">
                    <span class="ml-3 text--secondary">
                      {{ summary }}
                    </span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="col-12" align="center">
                    Pipeline Steps:
                    <v-btn-toggle multiple v-model="selected_steps"
                      :class="{vertical: isMobile}">
                      <v-tooltip top>
                        <template v-slot:activator="{on, attrs}">
                          <v-btn value="detection" small v-bind="attrs" v-on="on">
                            Detection
                            <v-icon v-if="selected_steps.includes('detection')" right dark> mdi-checkbox-marked-circle-outline </v-icon>
                            <v-icon v-else right dark> mdi-checkbox-blank-circle-outline </v-icon>
                          </v-btn>
                        </template>
                        <span>Only works for light trap images with bright background</span>
                      </v-tooltip>
                      <v-btn value="classification" small>
                        Classification
                        <v-icon v-if="selected_steps.includes('classification')" right dark> mdi-checkbox-marked-circle-outline </v-icon>
                        <v-icon v-else right dark> mdi-checkbox-blank-circle-outline </v-icon>
                      </v-btn>
                    </v-btn-toggle>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="col-12 col-lg-6" align="right">
                    <v-btn small class="col-12 error mx-1" @click="reset">
                      Clear <v-icon right dark> mdi-trash-can </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col class="col-12 col-lg-6" align="right">
                    <v-btn
                      class="col-12"
                      small
                      color="success"
                      @click="runPipeline"
                      :loading="pipelineCalled"
                      :disabled="pipelineCalled"
                    >
                      Run Pipeline <v-icon right dark> mdi-run </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col v-if="boxes?.length != 0" class="col-12">
                    <v-alert
                      dense text
                      type="info"
                      border="right"
                      elevation="2"
                      colored-border
                    >
                      Click on a bounding box to see more information about the prediction.
                    </v-alert>
                  </v-col>
                </v-row>
              </v-col>

            </v-row>

            <v-row v-if="error">
              <v-col class="col-12">
                <v-alert
                  v-model="failed"
                  dense
                  type="error"
                  border="right"
                  colored-border
                  text
                  dismissible
                  elevation="2"
                >
                  {{ error }}
                </v-alert>
              </v-col>
            </v-row>
          </div>
          <v-row v-else
            id="upload-text"
            class="d-flex flex-column"
            @click="$refs.file.click()"
            @mouseover="dragover = true"
            @mouseleave="dragover = false"
            dense
          >
            <v-col class="col-12" align="center">
              <v-icon :class="[dragover ? 'mt-2, mb-6' : 'mt-5']" size="48">
                mdi-cloud-upload
              </v-icon>
              <p>Drop your file here, or click to select one.</p>
            </v-col>
          </v-row>
        </v-card-text>

      </v-card>
    </div>

</template>

<script>
import DataService from "@/services/data.service";
import prettyBytes from 'pretty-bytes';


export default {
  name: 'ImageUploader',
  computed: {

    result: function() {
      return this.$route.params?.result
    },
    isMobile: function() {
      return window.innerWidth < 600;
    }

  },

  data: () => ({
    dragover: false,
    pipelineCalled: false,
    failed: false,
    error: undefined,
    file: undefined,
    summary: undefined,

    origSize: undefined,

    selected_steps: ["classification"],

    boxes: [
      {
        box: [0.25, 0.25, 0.5, 0.5],
        pred: "hypomecis punctinalis",
        score: 0.1
      }
    ],
  }),

  methods: {
    prettyBytes,

    loaded(info) {

      if (info.resized)
          this.summary = `${info.name} (${info.width}x${info.height}px. Original size: ${info.origWidth}x${info.origHeight}px, ${prettyBytes(info.size)})`;
      else
          this.summary = `${info.name} (${info.width}x${info.height}px, ${prettyBytes(info.size)})`;
    },


    reset() {
      this.dragover = false;
      this.file = undefined;
      this.origSize = undefined;
      this.pipelineCalled = false;
      this.imageSummary = undefined;

      this.boxes = [];

      // will close the info
      this.$emit("showInfo", undefined, undefined);
    },


    handleFiles(files){

      if (files.length > 1) {
        let message = "Only one file can be uploaded at a time";
        console.warn(message)
      }
      this.reset();
      this.file = files[0];

      console.log(this.file)
    },

    onClick(e) {
      this.handleFiles(e.target.files);

    },

    onDrop(e) {
      this.handleFiles(e.dataTransfer.files);
    },

    select(selectedIdx){
      var bComps = this.$refs.boxComponents;

      bComps.forEach((box, i) => {
        box.select(i === selectedIdx)
      });

      var wasSelected = bComps[selectedIdx]?.selected;

      this.$emit("showInfo",
        this.file,
        wasSelected ? this.boxes[selectedIdx] : undefined);
    },

    setResult(response) {
      var data = response.data;
      var success = data.status === "done";
      var failed = data.status === "failed";
      this.pipelineCalled = !(success || failed);

      if (failed){
        this.failed = failed;
        this.error = data.error;
      }

      if (data.result !== undefined)
        this.boxes = data.result;

      return !this.pipelineCalled;
    },

    runPipeline() {
      this.pipelineCalled = true;
      this.failed = false;
      this.boxes = [];

      DataService.predict(
        this.file,
        this.selected_steps,
        this.setResult
      )
    },

    loadResult(value) {
      let result = value || this.result;
      if (result === undefined)
        return
      console.log(result)
      DataService.load(result, this.setResult).then(
        (file) => {
          console.log(file)
          this.file = file
        });

    },

  },

  watch: {
    result: function(newVal){
      this.loadResult(newVal);
    }
  },

  created: function() {
    this.loadResult();
  },

}
</script>

<style scored>
  .overlay {
    position: relative;
  }

  #upload-text {
    cursor: pointer;
  }

  .vertical {
    flex-direction: column;
  }
</style>
