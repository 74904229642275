<template>

  <v-container>

    <v-row>
      <v-col
        class="col-12 col-xl-8"
        :class="{'offset-xl-2': crop === undefined}"
      >
        <v-card
          class="impress"
          min-height="35vh"
          rounded="lg"
          elevation="2"
        >
          <v-card-title>
            <div class="text-h4">
              Detection and Classification Demo
            </div>

            <v-col align="right">
              <v-btn x-small color="primary" @click="dialog=true">
                Show examples
              </v-btn>
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-alert
                  text
                  type="info"
                  border="left"
                  colored-border
                >
                  Here, you can try out our algorithms for detection and classification of moth species. Just drag&drop an image into the box below and run the pipeline.
                </v-alert>
              <core-ImageUploader
                ref="uploader"
                @showInfo="showInfo"
              />
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col class="col-12 col-xl-4">
        <core-InfoCrop
          ref="infoCrop"
          v-if="crop"
          :crop="crop"
          @close="crop = undefined; $refs.uploader.select(-1);"
        />

      </v-col>
    </v-row>

    <core-ExampleDialog v-model="dialog"/>
  </v-container>
</template>


<script>

export default {
  name: 'IndexView',

  data: () => ({
    crop: undefined,
    dialog: false,
  }),

  methods: {
    showInfo(file, box) {
      this.crop = box === undefined ? undefined : {file, box};
    }
  },

  computed: {

  },
}
</script>
