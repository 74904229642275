<template>
  <div class="bounding-box"
    :style="style"
    @mouseover="hover = true"
    @mouseleave="hover = false"
    @click="handleClick($event)"
    @contextmenu="handleClick($event, true)"
    :class="{active: selected || hover}"
  >
    <div v-if="label" class="label pa-1">{{label}}</div>
  </div>
</template>



<script>
export default {
  name: "BoundingBox",

  data: () => ({
      selected: false,
      hover: false,

      clicks: 0,
      timer: null,
  }),

  props: {
    color: {
      type: String,
      default: "0, 0, 255"
    },
    position: {
      type: Array
    },
    label: {
      type: String
    },
    score: {
      type: Number
    },
    dblClickInterval: {
      type: Number,
      default: -1,
    },
  },

  computed: {
    x: function(){ return this.position[0]; },
    y: function(){ return this.position[1]; },
    w: function(){ return this.position[2] - this.position[0]; },
    h: function(){ return this.position[3] - this.position[1]; },

    style: function () {
      var alpha = (this.selected===true || this.hover===true) ? 0.45 : 0.3;

      return {
        backgroundColor: `rgba(${this.color}, ${alpha})`,
        borderColor: `rgba(${this.color}, ${Math.min(1, alpha * 1.5)})`,
        top: this.y * 100 + '%',
        left: this.x * 100 + '%',
        width: this.w * 100 + '%',
        height: this.h * 100 + '%'
      }
    }
  },

  methods: {
    select(value) {
      // toggle selection or unselect
      this.selected = value ? !this.selected : false;
    },

    resetClicks(){
      clearTimeout(this.timer);
      this.clicks = 0;
    },

    singleClick(event, isRight){
      this.resetClicks();
      console.debug("SingleClick", isRight, event)
      this.$emit("selected");
    },

    doubleClick(event, isRight){
      this.resetClicks();
      console.debug("DoubleClick", isRight, event)

    },

    handleClick(event, isRight) {
      event.preventDefault();
      this.clicks++;
      isRight = isRight === undefined ? false : isRight;

      if (this.dblClickInterval <= 0)
        return this.singleClick(event, isRight)

      if (this.clicks > 1) {
        this.doubleClick(event, isRight);
        return;
      }

      var self = this;
      this.timer = setTimeout(function(){
        self.singleClick(event, isRight);
      }, this.dblClickInterval)
    }
  },

}
</script>

<style scoped>
.active {
  z-index: 2;
}
.bounding-box {
  position: absolute;
  border: 1px solid;
  z-index: 1;
  cursor: pointer;
}

.label {
  position: absolute;
  bottom: 100%;
  left: 0%;
  text-align: center;

  font-size: 0.8em;
  font-weight: bold;
  white-space: nowrap;

  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid;
  border-color: rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  z-index: 1;
}

.active > .label {
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 2;
}

</style>
