<template>
  <v-app id="moth-scanner">

    <!--<v-navigation-drawer app></v-navigation-drawer>-->

    <v-app-bar
      app
      flat
      dark
      elevation="4"
    >
      <core-AppBar/>
    </v-app-bar>


    <v-main
      class="grey lighten-3"

    >
      <v-container style="margin-bottom: 120px;">
        <router-view/>
      </v-container>
    </v-main>


    <v-footer
      color="indigo lighten-1"
      dark
      padless
      fixed
      elevation="24"
      >
      <core-AppFooter/>
    </v-footer>
  </v-app>
</template>


<script>
  export default {
    name: 'App',
  }
</script>
