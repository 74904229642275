<template>

  <div>
    <v-row v-if="this.examples.length != 0">
      <v-col
        v-for="(ex, i) in this.examples"
        :key="i"
        class="col-12 col-md-3 col-lg-6"
      >
        <a target="_new" :href="ex.identifier">
          <v-img
            :src="ex.identifier"
            aspect-ratio="1.778"
            max-height="240"/>
        </a>
      </v-col>
    </v-row>

    <v-alert v-else
      border="top"
      type="warning"
      colored-border
      elevation="2"
    >
      There were no example images
    </v-alert>
  </div>

</template>


<script>
import DataService from "@/services/data.service";
export default {
  name: 'SpeciesExamples',

  data: () => ({
    examples: []
  }),

  props: {
    prediction: {type: String},
    info: {type: Object}
  },

  computed: {

    taxonID: function() {
      const labels = this.info.labels;


      for (let i in labels){
        const label = labels[i];
        console.log(label);
        if(label.id !== undefined)
          return label.id;
      }

      return undefined;
    },

  },

  watch: {
    info: function(info) {
      this.updateInfo(info);
    }
  },


  created: function() {
    this.updateInfo(this.info);
  },

  methods: {
    setExamples: function(results){
      var chosen = [];
      var i = Math.min(results.length, 4);
      for (; i > 0; i--) {

        var idx = undefined;
        while (idx === undefined || chosen.includes(idx))
          idx = Math.floor(results.length * Math.random());

        chosen.push(idx);
        console.debug(idx);
        this.examples.push(results[idx]);
      }
    },

    updateInfo(info){
      const labels = info.labels;

      this.examples = [];
      for (let i in labels){
        const label = labels[i];
        DataService.getSpeciesExamples(
          label.id, label.rank, this.setExamples);

      }
    }
  },

}
</script>
