<template>

  <v-img :src="src" :max-height="maxHeight" :contain="contain"/>

</template>



<script>

export default {
  name: "ImageComponent",

  data: () => ({
    origSize: undefined,
    image: undefined,
  }),

  props: {
    file: undefined,
    box: undefined,
    maxHeight: undefined,
    contain: undefined,
    max_width: {
      type: Number,
      default: 1280,
    },
    max_height: {
      type: Number,
      default: 720,
    },
    resize: {
      type: Boolean,
      default: false,
    },

  },

  watch: {
    file: function(newVal){
      console.log("new file prop!")
      this.loadImage(newVal);
    },
    box: function(){
      console.log("new box prop!")
      this.loadImage(this.file);
    },
  },

  created: function() {
    this.loadImage(this.file);
  },

  methods: {

    getSize(img){
      var w = img.width;
      var h = img.height;

      var w_scale = this.max_width / w;
      var h_scale = this.max_height / h;

      var scale = Math.min(w_scale, h_scale);

      if (scale > 1)
        return undefined;

      return {w: w * scale, h: h * scale}
    },

    crop(img) {
      if (this.box === undefined){
        console.debug("no box given, hence no cropping");
        return img;
      }

      console.debug("cropping img")
      var w = img.width, h = img.height;
      var box = this.box.box;
      var x0 = box[0] * w,
          y0 = box[1] * h,
        boxW = (box[2] - box[0]) * w,
        boxH = (box[3] - box[1]) * h;


      // Dynamically create a canvas element
      var canvas = document.createElement("canvas");
      canvas.width = boxW;
      canvas.height = boxH;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(img,
        x0,y0,boxW,boxH,
        0, 0, boxW, boxH,
        );

      var croppedImg = new Image();
      croppedImg.src = canvas.toDataURL(this.file.type);
      return croppedImg;
    },

    loadImage(file){

      var reader = new FileReader();
      reader.onload = (e) => {
        var img = new Image();
        img.src = e.target.result;
        console.debug("reader onload")

        img.onload = () => {
          console.debug("img onload")
          var newSize = this.getSize(img);
          if (!this.resize || !newSize) {
            console.debug("no resize")
            this.image = this.crop(img);
            this.$emit("loaded", this.summary);
            return;
          }

          this.origSize = {w: img.width, h: img.height};
          console.debug(`resizing from ${this.origSize.w}x${this.origSize.h} to ${newSize.w}x${newSize.h}`);
          // Dynamically create a canvas element
          var canvas = document.createElement("canvas");
          canvas.width = newSize.w;
          canvas.height = newSize.h;
          var ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, newSize.w, newSize.h);
          var resizedImg = new Image();
          resizedImg.src = canvas.toDataURL(this.file.type);
          this.image = this.crop(resizedImg);
          this.$emit("loaded", this.summary);
        };
      };
      reader.readAsDataURL(file);
    },
  },


  computed: {

    summary: function() {
      var result = {
        "name": this.file.name,
        "width": this.image.width,
        "height": this.image.height,
        "size": this.file.size,
      }

      if (this.origSize)
        result = {
          ...result,
          "resized": true,
          "origWidth": this.origSize.w,
          "origHeight": this.origSize.h,
        }

      return result
    },

    src: function(){
      if (this.image === undefined)
        return null
      return this.image.src;
    },

  },
}
</script>
