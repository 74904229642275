import axios from 'axios'
import store from '@/store'

const instance = axios.create({
  baseURL: store.getters.getGBIFUrl,
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
  },
})

export default instance;
