<template>

    <v-dialog
      v-model="localValue"
      max-width="800"
    >
      <v-card>
        <v-card-title class="text-h5">
          Example images
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-alert
              text dense
              outlined
              type="info"
            >
              Click on an example image to see the detections and classifications!
            </v-alert>
          <v-divider/>

          <v-row>
            <v-col
              v-for="uuid, i in examples" :key="i"
              align="center"
              :cols=6
            >
              <v-card
                @click="goto(uuid)"
                elevation=2
                >
                <v-img :src="imgSrc(uuid)"

                />
              </v-card>
            </v-col>
          </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
</template>


<script>
import DataService from "@/services/data.service";
import store from '@/store'

export default {
  name: "ExampleDialog",

  props: ["value"],
  data: () => ({
    examples: []
  }),
  model: { prop: 'value', event: 'dialog' },

  methods: {
    imgSrc(uuid) {
      return `${store.getters.getAPIUrl}/image/${uuid}`
    },

    goto(uuid) {
      if (this.$route.params?.result === uuid)
        return
      this.$router.push({
        name: 'result',
        params: {result: uuid}
      });
      this.$emit("dialog", false);
    }
  },

  computed: {
    localValue: {
      get: function(){ return this.value },
      set: function(value){ this.$emit('dialog', value) },
    }
  },

  created: function(){
    DataService.getExamples().then((resp) => {this.examples = resp.data})
  }
}
</script>
