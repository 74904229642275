import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
    getAPIUrl: () => {
      return process.env.VUE_APP_API_URL;
    },
    getGBIFUrl: () => {
      return "https://api.gbif.org/v1/";
    },
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
