import api from "./api";
import gbif from "./gbif";

class DataService {

  predict(file, steps, callback) {
    var data = new FormData();
    data.append('image', file);
    data.append('steps', steps);

    let config = {
      headers: {'content-type': 'multipart/form-data'},
      onUploadProgress: function (progressEvent) {
        console.log(progressEvent)
      },
    }
    return api.post('detect/', data, config)
      .then((response) => {
        console.log(response)
        this.poll_result(response.data.uuid, callback)
      })
  }

  poll_result(uuid, callback) {
    var counter = 10;
    var time = 1000;
    var timeIncrease = 0.5;

    (function poll(){
      counter--;
      if (counter <= 0)
        return;

      /* Here comes the actuall request */
      api.get(`result/${uuid}`)
        .then((response) => {
          if (callback(response))
            counter = -1;
        })
      /* End of the request */

      time += time * timeIncrease;
      setTimeout(poll, time);
    })();

    return
  }

  getExamples() {
    return api.get("examples")
  }

  getSpeciesExamples(id, rank, callback) {
    console.debug(id, rank);
    return gbif.get(`species/${id}/media?limit=10`)
      .then((response) => {
        let results = response.data.results
        // for( var res of results)
        //   console.debug(res.type);
        callback(results);
      })
  }

  load(resultID, callback) {
    const regex = /inline; filename="(.+)"/
    this.poll_result(resultID, callback);
    return api.get(`image/${resultID}`, { responseType: 'blob' })
      .then((response) => {
        let cont = response.headers["content-disposition"];
        const fileName = cont.match(regex)[1];
        const blob = response.data;
        return new File([blob], fileName, blob)
      })
  }
}

export default new DataService();
