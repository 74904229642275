import Vue from 'vue'
import VueRouter from 'vue-router'
import IndexView from '../views/IndexView.vue'
import ImpressView from '../views/ImpressView.vue'
import PrivacyView from '../views/PrivacyView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: IndexView
  },
  {
    path: '/:result',
    name: 'result',
    component: IndexView
  },
  {
    path: '/impress',
    name: 'impress',
    component: ImpressView
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: PrivacyView
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
