<template>
  <v-container class="py-0 fill-height">
    <v-app-bar-nav-icon>
      <router-link
        :to="{name: 'index'}"
        id="logo_link"
        custom>
        <v-icon
          x-large
          color="green"
        > mdi-bee </v-icon>
      </router-link>
    </v-app-bar-nav-icon>
    <v-spacer></v-spacer>
    <v-app-bar-title>
        <router-link
          id="logo_text"
          text
          :to="{name: 'index'}">
        Moth Scanner
      </router-link>
    </v-app-bar-title>
  </v-container>
</template>

<script>
  export default {
    name: "AppBar",
  }
</script>

<style scoped>
  #logo_link {
    color: transparent;
  }

  #logo_text {
    text-decoration: none;
    color: whitesmoke;
  }

  #logo_text:hover {
    color: #4CAF50;
  }

</style>
