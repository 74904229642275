<template>

  <v-footer
    app
    color="indigo lighten-1"
    dark
    padless
    fixed
    elevation="24"
    id="footer"
  >
    <v-row
      justify="center"
      no-gutters
    >
      <v-col
        class="text-center"
        cols="12"
      >
        <v-btn
          v-for="link in links"
          :key="link.text"
          :to="link.to"
          text
        >
          {{ link.text }}
        </v-btn>

        <v-btn
          v-for="icon in icons"
          :key="icon.icon"
          :href="icon.to"
          target="_blank"
          icon
        >
          <v-icon>{{ icon.icon }}</v-icon>
        </v-btn>
      </v-col>
      <v-col
        class="indigo lighten-2 text-center"
        cols="12"
      >

        <v-btn text target="_blank" href="https://inf-cv.uni-jena.de">
          {{ new Date().getFullYear() }} — CVG Jena
        </v-btn>
      </v-col>

    </v-row>
  </v-footer>
</template>

<script>

class Link {
  constructor(text, to){
    this.text = text;
    this.to = to;
  }
}
class Icon {
  constructor(icon, to){
    this.icon = icon;
    this.to = to;
  }
}

  export default {
    data: () => ({
      icons: [
        new Icon('mdi-twitter', 'https://twitter.com/CvJena'),
        new Icon('mdi-github', 'https://github.com/cvjena'),
      ],
      links: [
        new Link('Datenschutzerklärung', {name: 'privacy'}),
        new Link('Impressum', {name: 'impress'}),
      ],
    }),
  }
</script>


<style scoped>
  #footer a {
    font-size: smaller !important;
  }
</style>
